// import { FETCH_CUSTOMER_DATA_REQUEST, FETCH_CUSTOMER_DATA_SUCCESS, FETCH_CUSTOMER_DATA_FAILURE } from './getCustomerAction';

// const initialState = {
//   loading: false,
//   data: null,
//   error: null
// };

// export default function getCustomerReducer(state = initialState, action) {
//   switch (action.type) {
//     case FETCH_CUSTOMER_DATA_REQUEST:
//       return {
//         ...state,
//         loading: true,
//         error: null
//       };
//     case FETCH_CUSTOMER_DATA_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         data: action.payload,
//         error: null
//       };
//     case FETCH_CUSTOMER_DATA_FAILURE:
//       return {
//         ...state,
//         loading: false,
//         data: null,
//         error: action.error
//       };
//     default:
//       return state;
//   }
// }
// reducer.js

const initialState = {
  customerData: null,
  deviceData: null,
  userData: null,
  complianceData: null,
  warningData: null,
  agentData: null,
  rangerData: null,
  nableData: null,
  domotzData: null,
  intuneData: null,
  deviceCiscoAmpData: null,
  sndrZeekData: null,
  sndrScanData: null,
  trafficStatsData: null,
  interCommsData: null,
  nmapData: null,
  userComplianceData: null,
  azureData: null,
  officeData: null,
  changeLogData: null,
  deviceLinksData: null,
  deviceArchiveData: null,
  deviceLastSeenData: null,
  deviceWarningData: null,
  deviceMissingPatchesData: null,
  deviceMissingSoftwareData: null,
  deviceIgnoredIssuesData: null,
  devicePlatformsNotUpdatedData: null,
  deviceStaleData: null,
  deviceTypeData: null,
  devicePatchesData: null,
  deviceUsersData: null,
  userChangeLogsData: null,
  userLinksData: null,
  userArchiveData: null,
  userLastSeenData: null,
  userWarningData: null
};

const tableDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_DATA': {
      const { dataType, data } = action.payload;

      return {
        ...state,
        [dataType]: data
      };
    }
    default:
      return state;
  }
};

export default tableDataReducer;
