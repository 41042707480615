import { createStore, applyMiddleware, compose } from 'redux';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
// import persistedReducer from './reducer';

import reducer from './reducer';
import thunk from 'redux-thunk';
// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(
  reducer,
  compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f)
);

const { dispatch } = store;

const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;

export { store, dispatch, useSelector, useDispatch };
// const store = createStore(
//   persistedReducer,
//   compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f)
// );
// export { store };
