// import alerts from './Alerts';
import services from './Services';
// import devicePlatforms from './DevicePlatforms';
import integration from './Integration';
import messages from './Messages';
import autopilot from './Autopilot';
import customerSupport from './CustomerSupport';
// import sndr from './SNDR';
import calendar from './Calendar';
import settings from './Settings';
import dashboard from './dashboard';
import products from './Products';
// import inventory from './Inventory';
// import userPlatforms from './userPlatforms';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, messages, products, autopilot, calendar, services, integration, customerSupport, settings]
};

export default menuItems;
