import { IconLayersIntersect } from '@tabler/icons';

// constant
const icons = { IconLayersIntersect };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const integration = {
  type: 'group',
  children: [
    {
      title: 'Integration',
      type: 'item',
      url: 'integration',
      icon: icons.IconLayersIntersect,
      breadcrumbs: false
    }
  ]
};

export default integration;
