// tableDataReducer.js
const initialState = {
  payloadData: [] // Initial state for the filter data
  // ... other state properties
};

const filterPayloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_PAYLOAD_DATA':
      return {
        ...state,
        payloadData: action.payload
      };
    // ... other case statements for different actions
    default:
      return state;
  }
};

export default filterPayloadReducer;
