import { combineReducers } from 'redux';
// import persistConfig from './persistConfig';
// import { persistReducer } from 'redux-persist';

// reducer import
import customizationReducer from './customizationReducer';
import tableDataReducer from './tableDataReducers';
import filterPayloadReducer from './filterPayload/filterPayloadReducer';
import filterDataReducer from './filterData/filterDataReducer';
import schemaDataReducer from './schemas/schemaReducer';
import authReducer from './reducers/authReducers';
import mailReducer from './slices/mail';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  tableData: tableDataReducer,
  filterPayload: filterPayloadReducer,
  filterData: filterDataReducer,
  schemaData: schemaDataReducer,
  auth: authReducer,
  mail: mailReducer
});

export default reducer;

// const persistedReducer = persistReducer(persistConfig, reducer);
// export default persistedReducer;
