import { IconCalendar } from '@tabler/icons';

// constant
const icons = { IconCalendar };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const calendar = {
  id: 'calendar',
  // title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'calendarPanel',
      title: 'Calendar',
      type: 'item',
      url: '/calendar',
      icon: icons.IconCalendar,
      breadcrumbs: false
    }
  ]
};

export default calendar;
