import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const isUserLoggedIn = localStorage.getItem('access_token') !== null;
  return useRoutes([isUserLoggedIn ? MainRoutes : AuthenticationRoutes]);
}
