import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const services = {
  id: 'services',
  // title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'servicesPanel',
      title: 'Services',
      type: 'item',
      url: '/services',
      icon: icons.IconDashboard,
      breadcrumbs: false
    }
  ]
};

export default services;
