import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgotPassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword')));
const VerifyOtp = Loadable(lazy(() => import('views/pages/authentication/authentication3/VerifyOtp')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin3 />
    },
    {
      path: '/auth/login',
      element: <AuthLogin3 />
    },
    {
      path: '/auth/register',
      element: <AuthRegister3 />
    },
    {
      path: '/auth/forgot-password',
      element: <AuthForgotPassword3 />
    },
    {
      path: '/auth/reset-password',
      element: <AuthResetPassword />
    },
    {
      path: '/auth/verify-otp',
      element: <VerifyOtp />
    }
  ]
};

export default AuthenticationRoutes;
