import { IconUser } from '@tabler/icons';

// constant
const icons = { IconUser };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const customerSupport = {
  id: 'customerSupport',
  // title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'customerSupportPanel',
      title: 'Customer Support',
      type: 'item',
      url: '/customer-support',
      icon: icons.IconUser,
      breadcrumbs: false
    }
  ]
};

export default customerSupport;
