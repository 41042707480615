import { IconTemplate } from '@tabler/icons';

// constant
const icons = { IconTemplate };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const products = {
  id: 'products',
  // title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'productsPanel',
      title: 'Products',
      type: 'item',
      url: '/products',
      icon: icons.IconTemplate,
      breadcrumbs: false
    }
  ]
};

export default products;
