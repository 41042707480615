const initialState = {
  schemaData: []
};

const schemaDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SCHEMA_DATA':
      return {
        ...state,
        schemaData: action.payload
      };
    default:
      return state;
  }
};

export default schemaDataReducer;
