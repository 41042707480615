import { IconMessage } from '@tabler/icons';

// constant
const icons = { IconMessage };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const messages = {
  id: 'messages',
  // title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'messagesPanel',
      title: 'Messages',
      type: 'item',
      url: '/messages',
      icon: icons.IconMessage,
      breadcrumbs: false
    }
  ]
};

export default messages;
