import { IconDeviceLaptop } from '@tabler/icons';

// constant
const icons = { IconDeviceLaptop };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const autopilot = {
  id: 'autopilot',
  // title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'autopilotPanel',
      title: 'Autopilot',
      type: 'item',
      url: '/autopilot',
      icon: icons.IconDeviceLaptop,
      breadcrumbs: false
    }
  ]
};

export default autopilot;
