import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Messages = Loadable(lazy(() => import('views/messages')));
const Autopilot = Loadable(lazy(() => import('views/autopilot/Autopilot')));
const Calendar = Loadable(lazy(() => import('views/calendar/Calendar')));
const Services = Loadable(lazy(() => import('views/services/Services')));
const Products = Loadable(lazy(() => import('views/products/Products')));
const Integration = Loadable(lazy(() => import('views/integration/Integration')));
const Settings = Loadable(lazy(() => import('views/settings')));
const CustomerSupport = Loadable(lazy(() => import('views/customerSupport/CustomerSupport')));
const SavedProducts = Loadable(lazy(() => import('views/products/SavedProducts')));
const ProductDetail = Loadable(lazy(() => import('views/products/ProductDetails')));

// ==============================|| MAIN ROUTING ||============================== //

// const token = localStorage.getItem('access_token');

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'messages',
      element: <Messages />
    },
    {
      path: 'products',
      element: <Products />
    },
    {
      path: 'products/:id',
      element: <ProductDetail />
    },
    {
      path: 'products/saved-products',
      element: <SavedProducts />
    },
    {
      path: 'calendar',
      element: <Calendar />
    },
    {
      path: 'services',
      element: <Services />
    },
    {
      path: 'settings',
      element: <Settings />
    },
    {
      path: 'integration',
      element: <Integration />
    },
    {
      path: 'customer-support',
      element: <CustomerSupport />
    },
    {
      path: 'autopilot',
      element: <Autopilot />
    }
  ]
};

export default MainRoutes;
